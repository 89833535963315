import { HoneycombWebSDK, WebVitalsInstrumentation } from '@honeycombio/opentelemetry-web';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';

export function initHoneycombWebSDK() {
  try {
    if (import.meta.env.VITE_HONEYCOMB_API_KEY) {
      console.log('[Honeycomb] OpenTelemetry SDK enabled');
      const sdk = new HoneycombWebSDK({
        apiKey: import.meta.env.VITE_HONEYCOMB_API_KEY,
        serviceName: 'app',
        instrumentations: [getWebAutoInstrumentations(), new WebVitalsInstrumentation()],
      });

      sdk.start();
    }
  } catch (err) {
    console.error(err);
  }
}
